<template>

  <div>
    <h4 class="card-title">Team Map</h4>
         <img :src="image" alt="story-img" class="img-fluid py-3">
  </div> 
</template>
<script>
import { socialvue } from '../../config/pluginInit'
import chartimage from "../../../src/assets/images/org-chart.jpg"
export default {
  name: 'Team',
  mounted () {
    socialvue.index()
  },
  data: function () {
        return {
            image: chartimage,
          }
  }
}
</script>